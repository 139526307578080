export const Redio = (props) => {
    const { changed, id, isSelected, label, value, className, name } = props;
    return (
        <div className="RadioButton">
            <input
                id={id}
                className={className}
                onChange={changed}
                value={value}
                name={name}
                type="radio"
                checked={isSelected}
            />
            <label className="ps-2 pe-3" htmlFor={id}>{label}</label>
        </div>
    );
};