import React from 'react'
import EditUserSideBar from '../Components/Sidebar/EditUserSideBar'

function EditUserSetting() {
    return (
        <>
            {localStorage.getItem("token")
                &&
                <div className="col-lg-2">
                    <EditUserSideBar />
                </div>
            }
            <div className={`${localStorage.getItem("token") ? "col-lg-10" : "col-lg-12"}`}>
                uiuoiu
            </div>
        </>
    )
}

export default EditUserSetting