
import { NavLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import "./Sidebar.css";
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,

} from "react-icons/fa";



const Sidebar = () => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);
    const menuItem = [
        // {
        //     path: "/dashboard",
        //     name: "Dashboard",
        //     icon: <FaTh />
        // },
        {
            path: "/apps",
            name: "Apps",
            icon: <FaUserAlt />
        },
        {
            path: "/removed-apps",
            name: "Removed apps",
            icon: <FaRegChartBar />
        },
        {
            path: "/playstore",
            name: "Playstore",
            icon: <FaCommentAlt />
        },
        {
            path: "/adx",
            name: "ADX",
            // icon: <FaCommentAlt />
        },
        // {
        //     path: "/vpn",
        //     name: "VPN Setting (o)",
        //     icon: <FaCommentAlt />
        // },
        // {
        //     path: "/google",
        //     name: "GoogleOrganic",
        //     icon: <FaCommentAlt />
        // },
        // {
        //     path: "/other-setting",
        //     name: "OtherSetting(o)",
        //     icon: <FaCommentAlt />
        // },
        // {
        //     path: "/app-remove-flag",
        //     name: "App Remove Flag(o)",
        //     icon: <FaCommentAlt />
        // },

        // {
        //     path: "/productList",
        //     name: "Product List",
        //     icon: <FaThList />
        // }
    ]
    // const handleLogout = () => {
    //     localStorage.clear()
    //     navigate("/")
    //     window.location.reload()
    // }
    const handleLogoutBtn = () => {
        localStorage.clear()
    }
    return (
        <>

            {/* <div className="container"> */}
            <div className="sidebar">
                <div className="top_section">
                    <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">Logo</h1>
                    <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
                        <FaBars
                            onClick={toggle}
                        />
                    </div>
                </div>
                {
                    menuItem.map((item, index) => (
                        <a href={item.path} key={index} className="link" activeclassname="active">
                            <div className="icon">{item.icon}</div>
                            <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                        </a>
                    ))
                }
                <NavLink to="/" className="link" activeclassname="active" onClick={handleLogoutBtn}>
                    <div className="icon"></div>
                    <div style={{ display: isOpen ? "block" : "none" }} className="link_text">Logout</div>
                </NavLink>
            </div>


            {/* </div> */}
        </>

    );
};
export default Sidebar;