import React, { useState } from "react";
import "../Css/Playstore.css";
import AddButton from "../Components/Button/AddButton";
import Sidebar from "../Components/Sidebar/Sidebar";
import apiServices from "../services/apiServices";
import { useEffect } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Link } from "react-router-dom";

const Playstore = () => {
  const pageSize = 3;
  const [currentState, setCurrentState] = useState(1);
  const [pageState, setPageState] = useState(0);
  const [consoleData, setConsoleData] = useState([]);
  const [filterData, setFilterData] = useState("live");
  const [isEditButton, setEditIsButton] = useState(false);
  const [countData, setCountData] = useState(0);
  const [selectedConsoleData, setSelectedConsoleData] = useState("");
  const [consoleSearchData, setConsoleSearchData] = useState("");
  const [searchBtn, setSearchBtn] = useState("");
  // const [errors, setErrors] = useState({});
  const [playStoreData, setPlayStoreData] = useState({
    name: "",
    // url: "",
    device_owner: "",
    server_number: "",
    remark: "",
    status: "upload",
  });
  const handleConsoleSearch = async () => {
    setSearchBtn(consoleSearchData);
  };
  const createNotification = () => {
    NotificationManager.success("New record created successfully");
  };
  const deletenotification = () => {
    NotificationManager.error("Record Deleted successfully");
  };
  const handleInputPlaystore = (event) => {
    const { name, value } = event.target;
    setPlayStoreData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // console.log("consoleData", consoleData)

  // console Search

  // const handleConsoleSearch = () => {
  //     // console.log("consoleSearchData",consoleSearchData)
  //     setConsoleSearchButton(consoleSearchData)
  // }
  const handleCreateConsole = async (e) => {
    e.preventDefault();
    if (isEditButton) {
      console.log("SelectplayStoreData", selectedConsoleData);
      playStoreData.id = selectedConsoleData;
      console.log("playStoreData", playStoreData);

      const res = await apiServices.updateConsole(playStoreData);
      console.log("ConsoleRes++++", res);
      if (res) {
        setCountData(countData + 1);
      }
    } else {
      const res = await apiServices.createConsole(playStoreData);
      if (res) {
        setCountData(countData + 1);
        setPlayStoreData({
          name: "",
          // url: "",
          device_owner: "",
          server_number: "",
          remark: "",
          status: playStoreData.status,
        });
        createNotification("success");
      }
    }
  };
  const handleEditPlaystoreData = (data, id) => {
    setSelectedConsoleData(id);
    setPlayStoreData({
      name: data.name,
      // url: data.url,
      device_owner: data.device_owner,
      server_number: data.server_number,
      remark: data.remark,
      status: data.status,
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setEditIsButton(true);
  };
  const handleDeletePlaystoreData = async (id) => {
    const res = await apiServices.deleteConsole(id);
    console.log("ResDeleteConsole", res);
    if (res.console == "please first remove app then delete console") {
      deletenotificationerror("error");
    } else {
      deletenotification("error");
      setCountData(countData + 1);
    }
  };

  const deletenotificationerror = () => {
    NotificationManager.error("console name is already exist");
  };

  const getAllConsoleData = async () => {
    const res = await apiServices.getAllConsole(
      searchBtn,
      pageSize,
      currentState,
      filterData
    );
    setPageState(Math.ceil(res.data?.consoleData?.total_count / pageSize));
    setConsoleData(res.data?.consoleData?.finalArray);
  };
  const handlePagination = (e) => {
    setCurrentState(e.target.value);
  };
  useEffect(() => {
    getAllConsoleData();
  }, [countData, filterData, searchBtn, currentState]);

  const handleConsoleFilterData = (e) => {
    setFilterData(e.target.value);
    console.log("eeeee", e.target.value);
  };

  // console.log("consoleData!!!!!!!", consoleData)
  // const handlePageselect = () => {
  //     setRecordPerPage(10)
  // }
  return (
    <>
      {localStorage.getItem("token") && (
        <div className="col-lg-2" style={{ height: "100vh" }}>
          <Sidebar />
        </div>
      )}
      <NotificationContainer />
      <div
        className={`${localStorage.getItem("token") ? "col-lg-10" : "col-lg-12"
          }`}
      >
        <div className="container">
          <h3 className="heading-playstore">Playstore</h3>
          <form className="playstore-form">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className="playstore-label" htmlFor="name">
                    Name
                  </label>
                  <div>
                    <input
                      type="text"
                      name="name"
                      value={playStoreData.name}
                      className="form-control"
                      id="name"
                      placeholder="Enter Name"
                      onChange={handleInputPlaystore}
                    />
                    {/* {errors.name && <div>{errors.name}</div>} */}
                  </div>
                </div>
                {/* <div className="form-group">
                                    <label className='playstore-label' htmlFor="ConsoleUrl">URL</label>
                                    <div>
                                        <input
                                            type="text"
                                            name="url"
                                            value={playStoreData.url}
                                            className="form-control"
                                            id="ConsoleUrl"
                                            placeholder="Enter URL"
                                            onChange={handleInputPlaystore}
                                        />
                                    </div>
                                </div> */}
                <div className="form-group">
                  <label className="playstore-label" htmlFor="device">
                    Device Owner
                  </label>
                  <input
                    type="text"
                    name="device_owner"
                    value={playStoreData.device_owner}
                    className="form-control"
                    id="device"
                    placeholder="Enter Device Owner"
                    onChange={handleInputPlaystore}
                  />
                </div>
                <div className="form-group">
                  <label className="playstore-label" htmlFor="server">
                    Server Number
                  </label>
                  <input
                    type="text"
                    name="server_number"
                    value={playStoreData.server_number}
                    className="form-control"
                    id="server"
                    placeholder="Enter Server Number"
                    onChange={handleInputPlaystore}
                  />
                </div>
                <div className="form-group">
                  <label className="playstore-label" htmlFor="remark">
                    Remark
                  </label>
                  <input
                    type="text"
                    name="remark"
                    value={playStoreData.remark}
                    className="form-control"
                    id="remark"
                    placeholder="Enter Remark"
                    onChange={handleInputPlaystore}
                  />
                </div>
              </div>
            </div>
            <label className="playstore-label">Status</label>
            <div className="add-app-radio-btn-group d-flex">
              <div className="form-check radio-btn-all">
                <input
                  className="form-check-input"
                  type="radio"
                  name="status"
                  id="upload"
                  value="upload"
                  checked={playStoreData.status === "upload"}
                  onChange={handleInputPlaystore}
                />
                <label className="form-check-label" htmlFor="upload">
                  Upload
                </label>
              </div>
              <div className="form-check radio-btn-all">
                <input
                  className="form-check-input"
                  type="radio"
                  name="status"
                  id="review"
                  value="InReview"
                  checked={playStoreData.status === "InReview"}
                  onChange={handleInputPlaystore}
                />
                <label className="form-check-label" htmlFor="review">
                  In Review
                </label>
              </div>
              <div className="form-check radio-btn-all">
                <input
                  className="form-check-input"
                  type="radio"
                  name="status"
                  id="live"
                  value="live"
                  checked={playStoreData.status === "live"}
                  onChange={handleInputPlaystore}
                />
                <label className="form-check-label" htmlFor="live">
                  Live
                </label>
              </div>
              <div className="form-check radio-btn-all">
                <input
                  className="form-check-input"
                  type="radio"
                  name="status"
                  id="suspended"
                  value="suspended"
                  checked={playStoreData.status === "suspended"}
                  onChange={handleInputPlaystore}
                // defaultChecked=""
                />
                <label className="form-check-label" htmlFor="suspended">
                  Suspended
                </label>
              </div>
              {/* <label>Update Date</label> */}
            </div>
            <AddButton
              onClick={handleCreateConsole}
              buttonName={"Add"}
              isEditButton={isEditButton}
              buttonStyle={"playstore-button-style"}
              disabled={playStoreData.name == ""}
            />
          </form>
        </div>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <label for="cars">Page show:</label>
              <select onChange={handlePagination}>
                {[...Array(pageState)]?.map((page, i) => (
                  // <div
                  //     className="d-flex"
                  //     key={i}
                  //     style={{
                  //         backgroundColor:
                  //             i === currentState ? "#F2F3F7" : "",
                  //         borderRadius: "8px",
                  //     }}
                  // >
                  <option value={i + 1}>{i + 1}</option>
                  // </div>
                ))}
              </select>
            </div>
            {/* filter data */}
            <div>
              <select onChange={handleConsoleFilterData}>
                <option value="live">Live</option>
                <option value="InReview">In Review</option>
                <option value="upload">Upload</option>
                <option value="suspended">Suspended</option>
              </select>
            </div>
            <div className="playstore-search justify-content-end d-flex">
              <input
                className="form-control"
                type="text"
                placeholder="Search"
                onChange={(e) => setConsoleSearchData(e.target.value)}
                aria-label="Search"
              />
              <button className="search-btn" onClick={handleConsoleSearch}>
                {" "}
                Search
              </button>
            </div>
          </div>
          <div className="table-responsive mt-4">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Device owner</th>
                  <th>Server Number</th>
                  <th>Remarks</th>
                  <th>Status</th>
                  <th>Up</th>
                  <th>Live</th>
                  <th>Suspended</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {/* {console.log("consoleData:::", consoleData)} */}
                {consoleData?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Link
                        to={`https://play.google.com/store/apps/developer?id=${item.name}`}
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        {item.name}
                      </Link>
                    </td>
                    <td>{item.device_owner}</td>
                    <td>{item.server_number}</td>
                    <td>{item.remark}</td>
                    <td>{item.status}</td>
                    <td>{item.up}</td>
                    <td>{item.live}</td>
                    <td>{item.sus}</td>
                    <td>
                      <div className="d-flex justify-content-around">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleEditPlaystoreData(item, item._id)
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </span>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeletePlaystoreData(item._id)}
                        >
                          <FontAwesomeIcon
                            style={{ color: "red" }}
                            icon={faTrash}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Playstore;
