import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'


const JsonDataMarketing = () => {
    const [jsonDataMarketing, setJsonDataMarketing] = useState({});

    useEffect(() => {
        if (localStorage.getItem("id")) {
            if (localStorage.getItem("appAllData")) {
                const data = JSON.parse(localStorage.getItem("appAllData"))
                console.log("appAllData++++++++", data)
                setJsonDataMarketing(data)
            }
        }
    }, [])

    return (
        <>
            <pre>{JSON.stringify(jsonDataMarketing.marketing, null, 2)}</pre>
        </>
    )
}

export default JsonDataMarketing