import React, { useState } from 'react'
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';
import AddButton from '../Components/Button/AddButton';
import "../Css/VpnSettingOrganic.css"
import RadioButton from '../Components/RadioButton/RadioButton';
import EditUserSideBar from '../Components/Sidebar/EditUserSideBar';
import { Redio } from '../Components/Redio/Redio';
import apiServices from '../services/apiServices';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { TagsInput } from "react-tag-input-component";
import Header from '../Components/Header/Header';


// const animatedComponents = makeAnimated();


// const Countries = [
//     { label: "Albania", value: "Albania" },
//     { label: "Argentina", value: 54 },
//     { label: "Austria", value: 43 },
//     { label: "Cocos Islands", value: 61 },
//     { label: "Kuwait", value: 965 },
//     { label: "Sweden", value: 46 },
//     { label: "Venezuela", value: 58 }
// ];

const VpnSettingM = () => {

    const [vpn, setVpn] = useState("false")
    const [screenVpnDialog, setscreenVpnDialog] = useState("false")
    const [vpmDialogOpen, setVpmDialogOpen] = useState("false")
    const [vpnOrganicData, setVpnOrganicData] = useState({
        vpnUrl: "",
        vpnId: "",

    })
    const [VpnListCountry, setVpnListCountry] = useState([])
    const navigate = useNavigate();

    console.log("VpnListCountry::::", VpnListCountry)
    const handleVpnOrganicInput = (event) => {

        const { name, value } = event.target;
        setVpnOrganicData((prevData) => ({
            ...prevData,
            [name]: value,

        }))
    }
    // console.log("vpnOrganicData", vpnOrganicData)
    // const handleCountryChange = (selectedOption) => {
    //     setSelectedCountry(selectedOption)
    // }
    const handleVpn = (e) => {
        setVpn(e.target.value)
    }
    const handleVpmDialog = (e) => {
        setscreenVpnDialog(e.target.value)
    }
    const handleVpmDialogOpen = (e) => {
        setVpmDialogOpen(e.target.value)
    }
    const createNotification = () => {
        NotificationManager.success('Record updated successfully');
    }
    const handleUpdateVpnSetting = async () => {
        const organicData = JSON.parse(localStorage.getItem("appAllData"))
        const vpn_settings = {
            vpn: vpn,
            vpn_dialog: screenVpnDialog,
            vpn_dialog_open: vpmDialogOpen,
            vpn_list_country: VpnListCountry,
            vpn_url: vpnOrganicData.vpnUrl,
            vpn_carrier_id: vpnOrganicData.vpnId,
        }
        organicData["marketing"] = { ...organicData["marketing"], vpn_settings }
        const res = await apiServices.updateApp(organicData, localStorage.getItem("id"), "marketing");
        console.log("vpnmarketingRes::::", res)
        if (res) {
            localStorage.setItem("appAllData", JSON.stringify(res.AppData))
            createNotification('success')
        }

    }

    useEffect(() => {
        if (localStorage.getItem("id")) {
            if (localStorage.getItem("appAllData")) {
                const data = JSON.parse(localStorage.getItem("appAllData"))
                if (data.marketing?.vpn_settings != null) {
                    setVpnOrganicData({
                        vpnUrl: data?.marketing?.vpn_settings.vpn_url,
                        vpnId: data?.marketing?.vpn_settings.vpn_carrier_id
                    })
                    setVpnListCountry(data?.marketing?.vpn_settings?.vpn_list_country)
                    setVpn(data?.marketing?.vpn_settings.vpn)
                    setVpmDialogOpen(data?.marketing?.vpn_settings.vpn_dialog_open)
                    setscreenVpnDialog(data?.marketing?.vpn_settings.vpn_dialog)
                }
            }
        } else {
            navigate("/apps")
        }
    }, [])


    return (
        <>
            <Header />
            {localStorage.getItem("token")
                &&
                <div className="col-lg-2">
                    <EditUserSideBar />
                </div>
            }
            <NotificationContainer />

            <div className={`${localStorage.getItem("token") ? "col-lg-10" : "col-lg-12"}`}>
                <div className="vpn-container">
                    <div className='vpn-heading'>
                        <h3>VPN Setting</h3>
                    </div>
                    <form className='vpn-organic-form'>
                        <div className='row'>
                            <div className='col-lg-6 d-flex justify-content-between'>
                                <p> VPN</p>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6 d-flex'>
                                <Redio
                                    changed={handleVpn}
                                    className={""}
                                    name={"screen-show"}
                                    id={"screen-show"}
                                    isSelected={vpn == "true"}
                                    label="Show"
                                    value={"true"}
                                />
                                <Redio
                                    changed={handleVpn}
                                    className={""}
                                    name={"screen-show"}
                                    id={"screen-hide"}
                                    isSelected={vpn == "false"}
                                    label="Hide"
                                    value={"false"}
                                />

                            </div>
                        </div>
                        <div className='row'>


                            <div className='col-lg-6 d-flex justify-content-between'>
                                <p> VPN Dialog</p>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6 d-flex'>

                                <Redio
                                    changed={handleVpmDialog}
                                    className={""}
                                    name={"VPN-Dialog-show"}
                                    id={"VPN-Dialog"}
                                    isSelected={screenVpnDialog == "true"}
                                    label="Show"
                                    value={"true"}
                                />
                                <Redio
                                    changed={handleVpmDialog}
                                    className={""}
                                    name={"VPN-Dialog-show"}
                                    id={"VPN-Dialog-hide"}
                                    isSelected={screenVpnDialog == "false"}
                                    label="Hide"
                                    value={"false"}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 d-flex justify-content-between'>
                                <p> VPN Dialog Open</p>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6 d-flex'>
                                <Redio
                                    changed={handleVpmDialogOpen}
                                    className={""}
                                    name={"VPN-Dialog-open-show"}
                                    id={"VPN-Dialog-open"}
                                    isSelected={vpmDialogOpen == "true"}
                                    label="Show"
                                    value={"true"}
                                />
                                <Redio
                                    changed={handleVpmDialogOpen}
                                    className={""}
                                    name={"VPN-Dialog-open-show"}
                                    id={"VPN-Dialog-open-hide"}
                                    isSelected={vpmDialogOpen == "false"}
                                    label="Hide"
                                    value={"false"}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 d-flex justify-content-between'>
                                <p> VPN List Country</p>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6'>
                                <TagsInput
                                    value={VpnListCountry}
                                    onChange={setVpnListCountry}
                                    name="vpn-country"

                                />
                                {/* <Select options={Countries}
                                    components={animatedComponents}
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    isMulti /> */}
                            </div>
                        </div>
                        <div className='row py-2'>
                            <div className='col-lg-6 d-flex justify-content-between '>
                                <label htmlFor="vpnUrl">VPN url</label>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="form-group d-flex  ">
                                    <input
                                        type="text"
                                        name="vpnUrl"
                                        value={vpnOrganicData.vpnUrl}
                                        className="form-control"
                                        id="vpnUrl"
                                        // placeholder="Enter Server Number"
                                        onChange={handleVpnOrganicInput}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 d-flex justify-content-between'>
                                <label htmlFor="vpnId">VPN Carrier Id</label>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="form-group d-flex  ">
                                    <input
                                        type="text"
                                        name="vpnId"
                                        value={vpnOrganicData.vpnId}
                                        className="form-control"
                                        id="vpnId"
                                        // placeholder="Enter Server Number"
                                        onChange={handleVpnOrganicInput}
                                    />
                                </div>
                            </div>
                        </div>


                    </form>
                </div>
                <AddButton buttonName={"update"} onClick={handleUpdateVpnSetting} />
            </div>
        </>
    )
}

export default VpnSettingM;