import Sidebar from "./Components/Sidebar/Sidebar";
import { Route, Routes } from 'react-router-dom';
// import Dashboard from './pages/Dashboard.jsx';
import Apps from './pages/Apps.jsx';
import OtherApps from './pages/RemovedApp.jsx';
import Playstore from './pages/Playstore.jsx';
// import Product from './pages/Product.jsx';
// import ProductList from './pages/ProductList.jsx';
// import Header from "./Components/Header/Header";
import "./App.css"
import Login from "./pages/Login";
import VpnSetting from "./pages/VpnSettingOrganic";
import GoogleSettingOrganic from "./pages/GoogleSettingOrganic";
import OtherSettingOrganic from "./pages/OtherSettingOrganic";
import AppRemoveFlagOrganic from "./pages/AppRemoveFlagOrganic";
import EditUserSetting from "./pages/EditUserSetting";
// import RemovedApp from "./pages/RemovedApp.jsx";
import GoogleSettingM from "./pages/GoogleSettingM";
import OtherSettingM from "./pages/OtherSettingM";
import VpnSettingM from "./pages/VpnSettingM";
import AppRemoveFlagM from "./pages/AppRemoveFlagM";
import AddSetting from "./pages/AddSetting";
import User from "./pages/User";
import AddSettingM from "./pages/AddSettingM";
import Protected from "./Components/Protected";
import BifurcateOrganic from "./pages/BifurcateOrganic";
import BifurcateM from "./pages/BifurcateM";
import RemovedApp from "./pages/RemovedApp.jsx";
import JsonData from "./pages/JsonData";

import Retantion from "./pages/Retanition";
import JsonDataMarketing from "./pages/JsonDataMarketing";
import Adx from "./pages/Adx";

const App = () => {
  return (
    <>
      {/* <Header /> */}
      <div className="row main-container">
        {/* {localStorage.getItem("token")
          &&
          <div className="col-lg-2">
            <Sidebar />
          </div>
        } */}
        {/* <div className={`${localStorage.getItem("token") ? "col-lg-10" : "col-lg-12"}`}> */}
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/" element={<Dashboard />} /> */}
          <Route path="/setting" element={<Protected Component={AddSetting} />} />
          <Route path="/setting-m" element={<Protected Component={AddSettingM} />} />
          <Route exact path="/bifurcate-organic" element={<Protected Component={BifurcateOrganic} />} />
          <Route exact path="/bifurcate-m" element={<Protected Component={BifurcateM} />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="/apps" element={<Protected Component={Apps} />} />
          <Route path="/other-apps" element={<Protected Component={OtherApps} />} />
          <Route path="/edit-user-setting" element={<Protected Component={EditUserSetting} />} />
          <Route path="/playstore" element={<Protected Component={Playstore} />} />
          <Route path="/adx" element={<Protected Component={Adx} />} />
          <Route exact path="/removed-apps" element={<RemovedApp />} />
          <Route path="/user-setting" element={<Protected Component={User} />} />
          <Route exact path="/vpn-organic" element={<Protected Component={VpnSetting} />} />
          <Route exact path="/google-organic" element={<Protected Component={GoogleSettingOrganic} />} />
          <Route exact path="/other-setting" element={<Protected Component={OtherSettingOrganic} />} />
          <Route exact path="/app-remove-flag" element={<Protected Component={AppRemoveFlagOrganic} />} />
          <Route exact path="/google-m" element={<Protected Component={GoogleSettingM} />} />
          <Route exact path="/other-m" element={<Protected Component={OtherSettingM} />} />
          <Route exact path="/vpn-m" element={<Protected Component={VpnSettingM} />} />
          <Route exact path="/app-remove-flag-m" element={<Protected Component={AppRemoveFlagM} />} />
          <Route exact path="/jsonData" element={<Protected Component={JsonData} />} />
          <Route exact path="/retantion" element={<Protected Component={User} />} />
          <Route exact path="/jsondata-m" element={<Protected Component={JsonDataMarketing} />} />
          {/* <Route path="/product" element={<Product />} />
            <Route path="/productList" element={<ProductList />} /> */}
        </Routes>
        {/* </div> */}
      </div>

    </>
  )
}
export default App;
