import React, { useEffect } from 'react';
import { useState } from 'react';
import AddButton from '../Components/Button/AddButton';
import "../Css/AppRemoveFlagOrganic.css"
import EditUserSideBar from '../Components/Sidebar/EditUserSideBar';
import apiServices from '../services/apiServices';
import { useNavigate } from 'react-router';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Header from '../Components/Header/Header';

const AppRemoveFlagOrganic = () => {
    const navigate = useNavigate()

    const [selectedFlag, setSelectedFlag] = useState({
        flags: "force",
        version: 0,
        title: "",
        description: "",
        flagUrl: "",
        buttonName: "",
        skipButton: ""
    });

    const handleFlagChange = (event) => {
        const { name, value } = event.target;
        setSelectedFlag((prevData) => ({
            ...prevData,
            [name]: value,

        }))
    };
    const createNotification = () => {
        NotificationManager.success('Record updated successfully');
    }
    const handleRemoveFlag = async () => {
        const organicData = JSON.parse(localStorage.getItem("appAllData"))
        const app_remove_flags = {
            flags: selectedFlag.flags,
            version: selectedFlag.version,
            title: selectedFlag.title,
            description: selectedFlag.description,
            url: selectedFlag.flagUrl,
            button_name: selectedFlag.buttonName,
            skip_button_name: selectedFlag.skipButton,
        }
        organicData["organic"] = { ...organicData["organic"], app_remove_flags }
        const res = await apiServices.updateApp(organicData, localStorage.getItem("id"), organicData.console_id);
        if (res) {
            localStorage.setItem("appAllData", JSON.stringify(res.AppData))
            createNotification('success')

        }

    }
    useEffect(() => {
        if (localStorage.getItem("id")) {
            if (localStorage.getItem("appAllData")) {
                const data = JSON.parse(localStorage.getItem("appAllData"))
                if (data.organic.app_remove_flags != null) {
                    setSelectedFlag({
                        flags: data.organic.app_remove_flags.flags,
                        version: data.organic.app_remove_flags.version,
                        title: data.organic.app_remove_flags.title,
                        description: data.organic.app_remove_flags.description,
                        flagUrl: data.organic.app_remove_flags.url,
                        buttonName: data.organic.app_remove_flags.button_name,
                        skipButton: data.organic.app_remove_flags.skip_button_name
                    })
                }
            }
        } else {
            navigate("/apps")
        }
    }, [])
    return (
        <>
            <Header />
            {/* App Remove Flags */}
            {localStorage.getItem("token")
                &&
                <div className="col-lg-2">
                    <EditUserSideBar />
                </div>
            }
            <NotificationContainer />

            <div className={`${localStorage.getItem("token") ? "col-lg-10" : "col-lg-12"}`}>
                <div className='app-remove-flag-container'>
                    <div className='heading-app-remove-flag'>
                        <h3 className='mb-3'>App Remove Flags</h3>
                    </div>
                    <form className='app-remove-flag-form'>
                        <div className='row'>
                            <div className='col-lg-6 d-flex justify-content-between'>
                                <h6>Flags</h6>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6 mb-4 d-flex '>

                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flags"
                                        value="force"
                                        checked={selectedFlag.flags == 'force'}
                                        onChange={handleFlagChange}
                                    />
                                    <label className="form-check-label">Force Update</label>

                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flags"
                                        value="skip"
                                        checked={selectedFlag.flags == 'skip'}
                                        onChange={handleFlagChange}
                                    />
                                    <label className="form-check-label">Skip Update</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flags"
                                        value="moveApp"
                                        checked={selectedFlag.flags == 'moveApp'}
                                        onChange={handleFlagChange}
                                    />
                                    <label className="form-check-label">MoveApp</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flags"
                                        value="normal"
                                        checked={selectedFlag.flags == 'normal'}
                                        onChange={handleFlagChange}
                                    />
                                    <label className="form-check-label">Normal</label>
                                </div>
                            </div>
                            {console.log("selectedFlag.flags:::::", selectedFlag.flags)}
                            <div className='col-lg-6 d-flex justify-content-between'>
                                <h6> Version</h6>
                                <div>:</div>

                            </div>
                            <div className='col-lg-6'>
                                <div className="form-group d-flex mb-3">
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="version"
                                        value={selectedFlag.version}
                                        onChange={handleFlagChange}

                                    />
                                </div>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-between'>
                                <h6> Title</h6>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="form-group d-flex mb-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="title"
                                        value={selectedFlag.title}
                                        onChange={handleFlagChange}

                                    />
                                </div>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-between'>
                                <h6> Description</h6>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="form-group d-flex mb-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="description"
                                        value={selectedFlag.description}
                                        onChange={handleFlagChange}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-between'>
                                <h6> Url </h6>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="form-group d-flex mb-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="flagUrl"
                                        value={selectedFlag.flagUrl}
                                        onChange={handleFlagChange}

                                    />
                                </div>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-between'>
                                <h6>Button Name</h6>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="form-group d-flex mb-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="buttonName"
                                        value={selectedFlag.buttonName}
                                        onChange={handleFlagChange}

                                    />
                                </div>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-between'>
                                <h6> Skip Button Name</h6>
                                <div>:</div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="form-group d-flex mb-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="skipButton"
                                        value={selectedFlag.skipButton}
                                        onChange={handleFlagChange}

                                    />
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
                <AddButton buttonName={"Update"} onClick={handleRemoveFlag} />
            </div>
        </>
    )
}

export default AppRemoveFlagOrganic