import React, { useEffect, useState } from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import apiServices from '../services/apiServices'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DeleteModal from '../Components/DeleteModel/DeleteModel'

const RemovedApp = () => {
    const [getDeleteAppData, setDeleteAppData] = useState([])
    const [pageRefresh, setPageRefresh] = useState(0)
    const [showModal, setShowModal] = useState(false);
    const [deletAppId, setDeletAppId] = useState();


    const handleModalClose = () => {
        setShowModal(false);
    };



    const handleDelete = async () => {
        const res = await apiServices.deletAppClear(deletAppId);
        console.log("resClearApp::::", res)
        if (res) {
            setPageRefresh(pageRefresh + 1)
            setShowModal(false);
        }
    };

    const handleRemovedAppData = async () => {
        const res = await apiServices.removedApp()
        setDeleteAppData(res.getDeletedApp)
    }
    useEffect(() => {
        handleRemovedAppData()
    }, [pageRefresh])

    const handleRestoreAppData = async (id) => {
        const res = await apiServices.restoreData(id)
        if (res) {
            setPageRefresh(pageRefresh + 1)
            createNotification('success')
        }
    }

    const handleDeleteRemovedApp = (id) => {
        setShowModal(true)
        setDeletAppId(id)
    }
    const createNotification = () => {
        NotificationManager.success('App restore successfully');
    }
    return (
        <>
            {localStorage.getItem("token")
                &&
                <div className="col-lg-2" style={{ height: "100vh" }}>
                    <Sidebar />
                </div>
            }
            <NotificationContainer />
            <div className={`${localStorage.getItem("token") ? "col-lg-10 pe-5" : "col-lg-12 pe-5"}`}>
                <h3 className='my-4'> Removed App</h3>
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">AppCode</th>
                            <th scope="col">Icon</th>
                            <th scope="col">App Name</th>
                            <th scope="col">Adx</th>
                            <th scope="col" >Today</th>
                            <th scope="col">Yesterday</th>
                            <th scope="col" >All</th>
                            <th scope="col">Notes</th>
                            <th scope="col">Date</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getDeleteAppData?.map((item, index) => (
                            <tr key={index}>
                                <td scope="row">{index + 1}</td>
                                <td>{item.addApp.app_code}</td>
                                <td><img src={item.addApp.icon} width="50" height="50" /> </td>
                                <td>{item.addApp.app_name}</td>
                                <td>{item.addApp.adx}</td>
                                <td>{item.addApp?.today}</td>
                                <td>{item.addApp?.yesterday}</td>
                                <td>{item.addApp?.all}</td>
                                <td>{item.addApp.notes}</td>
                                <td>{item.addApp.status}</td>
                                <td>
                                    <div className='d-flex justify-content-between'>
                                        <span className='d-flex ps-3 pt-2' style={{ cursor: "pointer" }} onClick={() => handleRestoreAppData(item._id)}><FontAwesomeIcon icon={faPen} /></span>
                                        <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleDeleteRemovedApp(item._id)}
                                        >
                                            <FontAwesomeIcon
                                                style={{ color: "red" }}
                                                icon={faTrash}
                                            />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        ))}


                    </tbody>
                </table>
            </div >
            <DeleteModal
                showModal={showModal}
                onClose={handleModalClose}
                onDelete={handleDelete} />
        </>
    )
}

export default RemovedApp