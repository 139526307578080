import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import apiServices from '../services/apiServices';
import { useNavigate } from 'react-router';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const Login = () => {
    const navigate = useNavigate("")
    const [emailData, setmailData] = useState("");
    const [passwordData, setPasswordData] = useState("");
    const deletenotification = () => {
        NotificationManager.error('Invalid username or password');
    }
    const hanldeLoginForm = async (e) => {
        e.preventDefault()
        const body = {
            email: emailData,
            password: passwordData
        }
        const res = await apiServices.loginForm(body)
        if (res) {
            navigate("/apps")
            window.location.reload()
        } else {
            deletenotification("error")
        }
    }
    return (
        <>
            <div>
                <NotificationContainer />
                <Container>
                    <Row className="vh-100 d-flex justify-content-center align-items-center">
                        <Col md={6} lg={4} xs={12}>
                            <Card className="shadow">
                                <Card.Body>
                                    <div className="mb-3 mt-md-4">
                                        <div className="mb-3">
                                            <Form>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-center">
                                                        Email address
                                                    </Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" onChange={(e) => setmailData(e.target.value)} />
                                                </Form.Group>

                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicPassword"
                                                >
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Password" onChange={(e) => setPasswordData(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicCheckbox"
                                                >
                                                    {/* <p className="small">
                                                        <a className="text-primary" href="#!">
                                                            Forgot password?
                                                        </a>
                                                    </p> */}
                                                </Form.Group>
                                                <div className="d-grid">
                                                    <Button variant="primary" type="submit" onClick={hanldeLoginForm}>
                                                        Login
                                                    </Button>
                                                </div>
                                            </Form>
                                            {/* <div className="mt-3">
                                                <p className="mb-0  text-center">
                                                    Don't have an account?{" "}
                                                    <a href="{''}" className="text-primary fw-bold">
                                                        Sign Up
                                                    </a>
                                                </p>
                                            </div> */}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Login