import React, { useState } from "react";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment/moment";

const CountModel = ({ showModal, onClose, onDelete, hourlyData }) => {
  // console.log("hourlyData:::", hourlyData)
  // console.log("------->")
  useState();

  let beforeNoon = Object.keys(hourlyData).slice(0, 12);
  let afterNoon = Object.keys(hourlyData).slice(12);

  // console.log("HourData", hourlyData)
  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Apps</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="container">
          <table class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Before Noon</th>
                <th>Count</th>
                <th>After Noon</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {hourlyData ? (
                beforeNoon.map((key, index) => (
                  <tr>
                    <td>{`${moment(
                      Number(beforeNoon[index].split("-")[0]) * 1000
                    )
                      .utc()
                      .format("HH:mm A")} - ${moment(
                        Number(beforeNoon[index].split("-")[1]) * 1000
                      )
                        .utc()
                        .format("HH:mm A")}`}</td>
                    {console.log("hourlyData && Objec", hourlyData)}
                    <td>{hourlyData[beforeNoon[index]].count}</td>

                    <td>{`${moment(
                      Number(afterNoon[index].split("-")[0]) * 1000
                    )
                      .utc()
                      .format("HH:mm A")} - ${moment(
                        Number(afterNoon[index].split("-")[1]) * 1000
                      )
                        .utc()
                        .format("HH:mm A")}`}</td>
                    <td>{hourlyData[afterNoon[index]].count}</td>
                  </tr>
                ))
              ) : (
                <tr>no data</tr>
              )}

              {/* <tr>
                                <td>00:00 AM-1:00 AM</td>
                                {console.log("hourlyData && Objec", hourlyData)}
                                <td>{hourlyData && Object.keys(hourlyData).length > 0 && hourlyData.userDownloadData && Object.keys(hourlyData.userDownloadData).length > 0 ? hourlyData.userDownloadData['1691971200-1691974800'].count : 0}</td>
                                <td>12:00 PM-13:00 PM</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td>1:00 AM-2:00 AM</td>
                                <td>0</td>
                                <td>13:00 PM-14:00 PM</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>2:00 AM-3:00 AM</td>
                                <td>0</td>
                                <td>14:00 PM-15:00 PM</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>3:00 AM-4:00 AM</td>
                                <td>0</td>
                                <td>15:00 PM-16:00 PM</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>4:00 AM-5:00 AM</td>
                                <td>0</td>
                                <td>16:00 PM-17:00 PM</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>5:00 AM-6:00 AM</td>
                                <td>0</td>
                                <td>17:00 PM-18:00 PM</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>6:00 AM-7:00 AM</td>
                                <td>0</td>
                                <td>18:00 PM-19:00 PM</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>7:00 AM-8:00 AM</td>
                                <td>0</td>
                                <td>19:00 PM-20:00 PM</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>8:00 AM-9:00 AM</td>
                                <td>0</td>
                                <td>20:00 PM-21:00 PM</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>9:00 AM-10:00 AM</td>
                                <td>0</td>
                                <td>21:00 PM-22:00 PM</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>10:00 AM-11:00 AM</td>
                                <td>0</td>
                                <td>22:00 PM-23:00 PM</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>11:00 AM-12:00 PM</td>
                                <td>0</td>
                                <td>23:00 PM-24:00 PM</td>
                                <td>1</td>
                            </tr> */}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={onDelete}>
                    Delete
                </Button>
            </Modal.Footer> */}
    </Modal>
  );
};
export default CountModel;
