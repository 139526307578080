import React, { useEffect } from "react";
import { useState } from "react";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import apiServices from "../../services/apiServices";
import { Link, createSearchParams } from "react-router-dom";
import DeleteModal from "../DeleteModel/DeleteModel";
import Loader from "../Loader/Loader";
import CountModel from "../CountModel/CountModel";
import "./TableData.css";
import moment from "moment/moment";

const TableData = (props) => {
    const [tableData, setTableData] = useState([]);
    const [countData, setCountData] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showCountModel, setShowCountModel] = useState(false);
    const [hourlyData, setHourlyData] = useState({});
    const pageSize = 10;
    const [currentState, setCurrentState] = useState(1);
    const [pageState, setPageState] = useState(0);
    const [countAll, setCountAll] = useState()


    const getTotalAppDownload = async () => {
        setShowLoader(true);
        const res = await apiServices.getAllUserCount()
        // console.log("resCountTotal", res)
        setCountAll(res?.userData)
        if (res) {
            setShowLoader(false)
        }
    }
    useEffect(() => {
        getTotalAppDownload()
    }, [])


    // pagination
    const handlePaginationApp = (e) => {
        setCurrentState(e.target.value);
    }



    const navigate = useNavigate();


    const handleGetTableData = async (data) => {
        setShowLoader(true);

        if (props.selectedTabName !== "add-apps") {
            if (data) {
                console.log("data:::::::::::", data)
                const res = await apiServices.getAllAppData(
                    props.selectedTabName,
                    props.searchApp,
                    pageSize,
                    currentState,
                    data
                );
                if (res) {
                    setShowLoader(false);
                }
                console.log("res????????????", res);
                setPageState(Math.ceil(res?.AppData.total_count / pageSize));
                setTableData(res?.AppData);
            }
            else {

                const res = await apiServices.getAllAppData(
                    props.selectedTabName,
                    props.searchApp,
                    pageSize,
                    currentState
                );

                //   console.log("res", res);
                if (res) {
                    setShowLoader(false);
                }
                // console.log("resapp++++", res.AppData)
                setPageState(Math.ceil(res?.AppData.total_count / pageSize));
                setTableData(res?.AppData);
            }

        }
    };

    const incrementData = () => {
        setCountData(countData + 1);
    };

    //   console.log("tableData", tableData);
    const handleEditUserSetting = (data, id) => {
        localStorage.setItem("appAllData", JSON.stringify(data));
        localStorage.setItem("id", id);
        navigate({
            pathname: "/google-organic",
            search: `?${createSearchParams({
                appTab: props.selectedTabName
            })}`
        }, {
            state: {
                data: data,
                id: id,
            },
        });
    };
    const handleEditData = (data, id) => {


        // console.log("editData+++", data)
        props.handleSelectTab("add-apps");
        // props.appSelectedData(data)
        props.setSelectedAppData(id);
        props.setAppTabFormData({
            play_store: data.addApp.play_store,
            adx: data.addApp.adx,
            app_code: data.addApp?.app_code,
            app_name: data.addApp?.app_name,
            icon: data.addApp?.icon,
            package_name: data.addApp.package_name,
            // web_url: data.addApp.web_url,
            notes: data.addApp.notes,
            // admin_url: data.addApp.admin_url,
            // user_url: data.addApp.user_url,
            status: data.addApp.status,
            upload_date: data.addApp.upload_date,
            date: data.addApp?.date,
            // console_id:data.console_id
        });
        // console.log("data.console_id:::::::::::::::::::::", data.console_id)
        if (data?.console_id) {
            props.setSelectedConsoleId(data?.console_id)
        }
        props.setIsEditData(true);
    };
    const handleChangeUploadData = async (data, id) => {
        // console.log("setConsoleId:::::::", data.console_id)

        const body = {
            addApp: {
                play_store: data.addApp.play_store,
                adx: data.addApp.adx,
                app_code: data.addApp?.app_code,
                app_name: data.addApp?.app_name,
                icon: data.addApp?.icon,
                package_name: data.addApp.package_name,
                // web_url: data.addApp.web_url,
                notes: data.addApp.notes,
                // admin_url: data.addApp.admin_url,
                // user_url: data.addApp.user_url,
                upload_date: data.addApp.upload_date,
                date: data.addApp?.date,
                status: "upload",
            },
        };
        // setConsoleId()
        // console.log("consoleIDData---------", data.console_id)
        const res = await apiServices.updateApp(body, id, data.console_id);
        if (res) {
            props.handleSelectTab("upload");
        }
        // console.log("res", res);
    };

    const handleChangeCtroData = async (data, id) => {
        // setConsoleId(data.console_id)
        const body = {
            addApp: {
                play_store: data.addApp.play_store,
                adx: data.addApp.adx,
                app_code: data.addApp.app_code,
                app_name: data.addApp.app_name,
                icon: data.addApp?.icon,
                package_name: data.addApp.package_name,
                // web_url: data.addApp.web_url,
                notes: data.addApp.notes,
                // admin_url: data.addApp.admin_url,
                // user_url: data.addApp.user_url,
                date: data.addApp?.date,
                status: "ctro",
            },
        };
        const res = await apiServices.updateApp(body, id, data.console_id);
        if (res) {
            props.handleSelectTab("ctro");
        }
        // console.log("ressCtro", res);
    };
    const handleDeleteData = (id) => {
        // console.log("id", id)
        setShowModal(true);
        setDeleteId(id);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleDelete = async () => {
        const res = await apiServices.deleteApp(deleteId);
        if (res) {
            incrementData();
            setShowModal(false);
        }
    };
    // For Count Model
    const handleCountModel = async (body, filterTime) => {
        let hourWiseData = false;
        setShowCountModel(true);
        if (filterTime == "today") {
            hourWiseData = true;
        } else if (filterTime == "yesterday") {
            hourWiseData = true;
        } else {
            hourWiseData = false;
        }
        const respose = await apiServices.getUserDownload(
            body.addApp.package_name,
            filterTime,
            hourWiseData
        );
        if (Object.keys(respose).length > 0) {
            setHourlyData(respose?.userDownloadData);
        }
    };

    const handleCountModelClose = () => {
        setShowCountModel(false);
    };

    useEffect(() => {
        // setShowLoader(true)
        handleGetTableData();
        // setShowLoader(false)
    }, [props.selectedTabName, props.searchApp, currentState, countData]);

    return (
        <>
            {props.selectedTabName == "live" && <div className='mt-4'>
                {countAll?.map((item, index) => (
                    <>
                        <div className="d-flex justify-content-between mb-4" key={index}>
                            <button type="button" class="btn btn-primary">Today Download:{item.downloadsToday}</button>
                            <button type="button" class="btn btn-secondary">Yesterday Download:{item.downloadsYesterday} </button>
                            <button type="button" class="btn btn-success">Last 7 days Download: {item.downloads7days}</button>
                            <button type="button" class="btn btn-dark">Last 30 Days Download: {item.downloads30days}</button>
                        </div>
                    </>

                ))}

            </div>
            }

            <div style={{ padding: "20px 0px" }}>
                <label for="cars">Page show:</label>
                <select onChange={handlePaginationApp}>
                    {[...Array(pageState)]?.map((page, i) => (
                        // <div
                        //     className="d-flex"
                        //     key={i}
                        //     style={{
                        //         backgroundColor:
                        //             i === currentState ? "#F2F3F7" : "",
                        //         borderRadius: "8px",
                        //     }}
                        // >
                        <option value={i + 1}>{i + 1}</option>
                        // </div>
                    ))}
                </select>
            </div>

            {showLoader ? (
                <div className="d-flex justify-content-center">
                    <Loader />
                </div>
            ) : (
                <div className="main-table-div">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">{props.tableHeadId}</th>
                                <th scope="col">{props.tableHeadAppCode}</th>
                                <th scope="col">{props.tableHeadIcon}</th>
                                <th scope="col">{props.tableHeadAppName}</th>
                                <th scope="col">{props.tableHeadAdx}</th>
                                <th onClick={() => handleGetTableData("today")}
                                    scope="col"
                                    style={{
                                        display:
                                            props.selectedTabName == "develope"
                                                ? "none"
                                                : "table-cell",
                                        cursor: "pointer",
                                        // backgroundColor: isActive ? "#198754" : '',
                                    }}
                                >

                                    {props.tableHeadToday} <span style={{ display: props.selectedTabName == "live" ? "tabel-cell" : "none" }}><FontAwesomeIcon style={{ paddingRight: "3px", paddingLeft: "3px" }} icon={faArrowUp} /><FontAwesomeIcon icon={faArrowDown} /> </span>
                                </th>
                                <th onClick={() => handleGetTableData("yesterday")}
                                    scope="col"
                                    style={{
                                        display:
                                            props.selectedTabName == "develope"
                                                ? "none"
                                                : "table-cell",
                                        cursor: "pointer",
                                        // backgroundColor: isActive ? "red" : '',
                                    }}
                                >
                                    {props.tableHeadYesterday}  <span style={{ display: props.selectedTabName == "live" ? "tabel-cell" : "none" }}><FontAwesomeIcon icon={faArrowUp} style={{ paddingRight: "3px", paddingLeft: "3px" }} /><FontAwesomeIcon icon={faArrowDown} /> </span>
                                </th>
                                <th onClick={() => handleGetTableData("allTime")}
                                    scope="col"
                                    style={{
                                        display:
                                            props.selectedTabName == "develope"
                                                ? "none"
                                                : "table-cell",
                                        cursor: "pointer",
                                        // backgroundColor: isActive ? "yellow" : '',
                                    }}
                                >
                                    {props.tableHeadAll}  <span style={{ display: props.selectedTabName == "live" ? "tabel-cell" : "none" }}><FontAwesomeIcon icon={faArrowUp} style={{ paddingRight: "3px", paddingLeft: "3px" }} /><FontAwesomeIcon icon={faArrowDown} /> </span>
                                </th>
                                <th scope="col">{props.tableHeadNotes}</th>
                                <th
                                    scope="col"
                                    style={{
                                        display:
                                            props.selectedTabName == "suspended"
                                                ? "none"
                                                : "table-cell",
                                    }}
                                >
                                    {props.tableHeadStatus}
                                </th>
                                <th scope="col">{props.tableHeadAction}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.AppData?.map((item, index) => (

                                <tr key={index}>
                                    <td scope="row">{index + 1}</td>
                                    <td>{item.addApp.app_code}</td>
                                    <td>
                                        <Link to={`https://play.google.com/store/apps/details?id=${item.addApp.package_name}`} target="_blank" >  <img src={item.addApp.icon} width="50" height="50" /> </Link>{" "}
                                    </td>
                                    <td>

                                        {/* <Link
                                            to={`https://play.google.com/store/apps/details?id=${item.addApp.package_name}`}
                                            style={{ display: "block" }}
                                        > */}
                                        <div className="appName">
                                            {item.addApp.app_name}
                                        </div>
                                        {/* {item.addApp.app_name} */}
                                        <div className="packagename" style={{ cursor: "auto" }}>
                                            {item.addApp.package_name}
                                        </div>
                                        <div className="consolename" style={{ cursor: "auto" }}>
                                            {item.addApp.play_store}
                                        </div>
                                        {/* </Link> */}
                                    </td>
                                    <td>{item.addApp.adx}</td>
                                    <td
                                        onClick={() => handleCountModel(item, "today")}
                                        style={{
                                            display:
                                                props.selectedTabName == "develope"
                                                    ? "none"
                                                    : "table-cell",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {item.downloadsToday}
                                    </td>
                                    <td
                                        onClick={() => handleCountModel(item, "yesterday")}
                                        style={{
                                            display:
                                                props.selectedTabName == "develope"
                                                    ? "none"
                                                    : "table-cell",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {item.downloadsYesterday}
                                    </td>
                                    <td
                                        style={{
                                            display:
                                                props.selectedTabName == "develope"
                                                    ? "none"
                                                    : "table-cell",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {item.downloadsAllTime}
                                    </td>

                                    <td>{item.addApp.notes}</td>
                                    {props.selectedTabName == "develope" && (
                                        <td
                                            style={{
                                                display:
                                                    props.selectedTabName == "suspended"
                                                        ? "none"
                                                        : "table-cell",
                                            }}
                                        >
                                            {item.addApp.status}
                                        </td>
                                    )}
                                    {props.selectedTabName == "ctro" && (
                                        <td

                                        >
                                            {item.addApp.status || "-"}
                                        </td>
                                    )}
                                    {props.selectedTabName == "upload" && (
                                        <td>
                                            {" "}
                                            {item.addApp.upload_date &&
                                                moment(item.addApp.upload_date).format("L")}
                                        </td>
                                    )}
                                    {props.selectedTabName == "live" && (
                                        <td>
                                            {item.addApp.date &&
                                                moment().diff(moment(item.addApp.date), "days")}{" "}
                                            days
                                        </td>
                                    )}
                                    {/* <td style={{ display: props.selectedTabName == "develope" ? "none" : "table-cell" }}>{item.addApp.upload_date}</td> */}
                                    <td>
                                        <div className="d-flex justify-content-around">
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleEditData(item, item.id)}
                                            >
                                                <FontAwesomeIcon icon={faPen} />
                                            </span>
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleDeleteData(item.id)}
                                            >
                                                <FontAwesomeIcon
                                                    style={{ color: "red" }}
                                                    icon={faTrash}
                                                />
                                            </span>
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleEditUserSetting(item, item.id)}
                                            >
                                                <FontAwesomeIcon icon={faUser} />
                                            </span>
                                        </div>
                                        <span
                                            style={{
                                                cursor: "pointer",
                                                textAlign: "left",
                                                paddingLeft: "15px",
                                                fontSize: "20px",
                                                display:
                                                    props.selectedTabName == "develope"
                                                        ? "block"
                                                        : "none",
                                            }}
                                            onClick={() => handleChangeUploadData(item, item.id)}
                                        >
                                            <FontAwesomeIcon
                                                style={{ color: "green" }}
                                                icon={faCheck}
                                            />
                                        </span>
                                        <span
                                            style={{
                                                cursor: "pointer",
                                                textAlign: "left",
                                                paddingLeft: "15px",
                                                fontSize: "20px",
                                                display:
                                                    props.selectedTabName == "live" ? "block" : "none",
                                            }}
                                            onClick={() => handleChangeCtroData(item, item.id)}
                                        >
                                            <FontAwesomeIcon
                                                style={{ color: "green" }}
                                                icon={faCheck}
                                            />
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <DeleteModal
                showModal={showModal}
                onClose={handleModalClose}
                onDelete={handleDelete}
            />

            <CountModel
                showModal={showCountModel}
                onClose={handleCountModelClose}
                hourlyData={hourlyData}
            />
            {/* <button onClick={handleCountModel}>hello </button> */}
        </>
    );
};

export default TableData;
