import React, { useEffect, useState } from "react";
import EditUserSideBar from "../Components/Sidebar/EditUserSideBar";
import "../Css/addSetting.css";
import { Redio } from "../Components/Redio/Redio";
import AddButton from "../Components/Button/AddButton";
import apiServices from "../services/apiServices";
import { useLocation, useNavigate } from "react-router";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Header from "../Components/Header/Header";

const AddSetting = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [adminAppColor, setAdminAppColor] = useState("#000000");
  const [nativeBackground, setnativeBackground] = useState("#FFFEFF");
  const [nativeBtnBackground, setnativeBtnBackground] = useState("#4285F4");
  const [nativeText, setnativeText] = useState("#808080");
  const [nativeButtonText, setnativeButtonText] = useState("#FFFEFF");
  const [appBackgroundColor, setAppBackgroundColor] = useState("#000000");
  const [bottombannerRedio, setBottomBannerRedio] = useState("bottom-banner");
  const [screenNative, setScreenNative] = useState("false");
  const [listNative, setListNative] = useState("false");
  const [listNativeInput, setListNativeInput] = useState("0");
  const [nativeCount, setNativeCount] = useState("0");
  const [exitDialoge, setExitDialogeNative] = useState("false");
  const [nativeBtnText, setnativeBtnText] = useState("default");
  const [nativeBtnLabel, setNativeBtnLabel] = useState("");
  const [spleshAddData, setSpleshAddData] = useState("OpenAds");
  const [appOpenData, setAppOpenData] = useState("Every Time");
  const [interData, setInterData] = useState("false");
  const [blackClick, setBlackClick] = useState("4");
  const [interInterval, setInterInterval] = useState("4");
  const [nativeLoading, setNativeLoading] = useState("false");
  const [appOpenLoading, setAppOpenLoading] = useState("false");
  const [alternateShow, setAlternateShow] = useState("false");
  // console.log()
  // console.log("blackClick++++++", blackClick)
  const handleNativebtnText = (e) => {
    setnativeBtnText(e.target.value);
  };
  const exitDialogeNative = (e) => {
    setExitDialogeNative(e.target.value);
  };
  const radioBottomBannerChangeHandler = (e) => {
    setBottomBannerRedio(e.target.value);
  };
  const radioAllScreenNativeChangeHandler = (e) => {
    setScreenNative(e.target.value);
  };
  const handleListNative = (e) => {
    setListNative(e.target.value);
    if (e.target.value == "false") {
      setListNativeInput("0");
    }
  };
  const handleListNativeInput = (e) => {
    setListNativeInput(e.target.value);
  };
  const handleNativeCount = (e) => {
    setNativeCount(e.target.value);
  };
  const handleNativeLoading = (e) => {
    setNativeLoading(e.target.value);
  };
  const createNotification = () => {
    NotificationManager.success("Record updated successfully");
  };
  const handleAddSettingOrganic = async () => {
    const organicData = JSON.parse(localStorage.getItem("appAllData"));
    const ads_settings = {
      app_color: {
        app_color_for_admin: adminAppColor,
        background_color: appBackgroundColor,
      },
      native: {
        native_loading: nativeLoading,
        bottom_banner:
          bottombannerRedio == "bottom-banner"
            ? "banner"
            : bottombannerRedio == "bottom-hide"
              ? "hide"
              : "native",
        all_screen_native: screenNative,
        list_native: listNative == "true" ? listNativeInput : "0",
        static_native_count: nativeCount,
        exit_dialog_native: exitDialoge,
        native_button_text:
          nativeBtnText == "default" ? "Default" : nativeBtnLabel,
        native_color: {
          background: nativeBackground,
          text: nativeText,
          button_background: nativeBtnBackground,
          button_text: nativeButtonText,
        },
      },
      inter: {
        inter_interval: interInterval,
        back_click_inter: blackClick,
        inter_loading: interData,
        alternate_app: alternateShow,
      },
      app_open: {
        splash_ads: spleshAddData,
        app_open: appOpenData,
        app_open_loading: appOpenLoading,
      },
    };
    organicData["marketing"] = { ...organicData["marketing"], ads_settings };

    const res = await apiServices.updateApp(
      organicData,
      localStorage.getItem("id"),
      organicData.console_id
      // "marketing"
    );
    console.log("resMarketingAddSetting*****", res);
    if (res) {
      localStorage.setItem("appAllData", JSON.stringify(res.AppData));
      createNotification("success");
    }
  };
  useEffect(() => {
    document.body.classList.add("addSetting-body");
    // console.log("localStorage.getItemid)", localStorage.getItem("id"))
    if (localStorage.getItem("id")) {
      // console.log("localStorage.getItem::", localStorage.getItem("appAllData"))
      if (localStorage.getItem("appAllData")) {
        const data = JSON.parse(localStorage.getItem("appAllData"));
        if (data.marketing.ads_settings != null) {
          setAdminAppColor(
            data?.marketing?.ads_settings?.app_color?.app_color_for_admin ==
              null
              ? "#4899e5"
              : data?.marketing?.ads_settings?.app_color?.app_color_for_admin
          );
          setAppBackgroundColor(
            data?.marketing.ads_settings.app_color.background_color == null
              ? "#F2F2F2"
              : data?.marketing.ads_settings.app_color.background_color
          );
          setNativeLoading(
            data?.marketing?.ads_settings?.native?.native_loading == null
              ? "false"
              : data?.marketing?.ads_settings?.native?.native_loading
          );
          setBottomBannerRedio(
            data?.marketing?.ads_settings?.native?.bottom_banner == null
              ? "bottom-native"
              : data?.marketing?.ads_settings?.native?.bottom_banner == "native"
                ? "bottom-native"
                : data?.marketing?.ads_settings?.native?.bottom_banner == "hide"
                  ? "bottom-hide"
                  : "bottom-banner"
          );
          setScreenNative(
            data?.marketing?.ads_settings?.native?.all_screen_native == null
              ? "true"
              : data?.marketing?.ads_settings?.native?.all_screen_native
          );

          setNativeCount(
            data?.marketing?.ads_settings?.native?.static_native_count == null
              ? "0"
              : data?.marketing?.ads_settings?.native?.static_native_count
          );

          setListNative(
            data?.marketing?.ads_settings?.native?.list_native == "0"
              ? "false"
              : "true"
          );
          setListNativeInput(
            data?.marketing?.ads_settings?.native?.list_native == "false"
              ? "0"
              : data?.marketing?.ads_settings?.native?.list_native
          );

          setExitDialogeNative(
            data?.marketing?.ads_settings?.native?.exit_dialog_native == null
              ? "false"
              : data?.marketing?.ads_settings?.native?.exit_dialog_native
          );

          setnativeBtnText(
            data?.marketing?.ads_settings?.native?.native_button_text == null
              ? "default"
              : data?.marketing?.ads_settings?.native?.native_button_text ==
                "Default"
                ? "default"
                : "Manual"
          );
          setNativeBtnLabel(
            data?.marketing?.ads_settings?.native?.native_button_text ==
              "Default"
              ? ""
              : data?.marketing?.ads_settings?.native?.native_button_text
          );

          // setListNative(data?.marketing
          //     ?.ads_settings?.native?.list_native == null ? "true" : data?.marketing
          //         ?.ads_settings?.native?.list_native)
          setnativeBtnText(
            data?.marketing?.ads_settings?.native?.native_button_text == null
              ? "defaults"
              : data?.marketing?.ads_settings?.native?.native_button_text ==
                "Default"
                ? "default"
                : data?.marketing?.ads_settings.native?.native_button_text
          );

          setnativeBackground(
            data?.marketing?.ads_settings?.native.native_color.background ==
              null
              ? "#F2F2F2"
              : data?.marketing?.ads_settings?.native.native_color.background
          );
          setnativeBtnBackground(
            data?.marketing?.ads_settings?.native.native_color
              .button_background == null
              ? "#31814d"
              : data?.marketing?.ads_settings?.native.native_color
                .button_background
          );
          setnativeText(
            data?.marketing?.ads_settings?.native.native_color.text == null
              ? "#000000"
              : data?.marketing?.ads_settings?.native.native_color.text
          );
          setnativeButtonText(
            data?.marketing?.ads_settings?.native.native_color.button_text ==
              null
              ? "#FFFFFF"
              : data?.marketing?.ads_settings?.native.native_color.button_text
          );

          setInterInterval(
            data?.marketing?.ads_settings?.inter?.inter_interval == null
              ? "0"
              : data?.marketing?.ads_settings?.inter?.inter_interval
          );
          setBlackClick(
            data?.marketing?.ads_settings?.inter?.back_click_inter == null
              ? "0"
              : data?.marketing?.ads_settings?.inter?.back_click_inter
          );
          setAlternateShow(
            data?.marketing?.ads_settings?.inter?.alternate_app == null
              ? "false"
              : data?.marketing?.ads_settings?.inter?.alternate_app
          );
          setInterData(
            data?.marketing?.ads_settings?.inter?.inter_loading == null
              ? "false"
              : data?.marketing?.ads_settings?.inter?.inter_loading
          );
          setSpleshAddData(
            data?.marketing?.ads_settings?.app_open?.splash_ads == null
              ? "OpenAds"
              : data?.marketing?.ads_settings?.app_open?.splash_ads
          );
          setAppOpenData(
            data?.marketing?.ads_settings?.app_open?.app_open == null
              ? "Every Time"
              : data?.marketing?.ads_settings?.app_open?.app_open
          );
          setAppOpenLoading(
            data?.marketing?.ads_settings?.app_open?.app_open_loading == null
              ? "false"
              : data?.marketing?.ads_settings?.app_open?.app_open_loading
          );
        }
      }
    } else {
      navigate("/apps");
    }
  }, []);
  return (
    <>
      <Header />
      {localStorage.getItem("token") && (
        <div className="col-lg-2">
          <EditUserSideBar />
        </div>
      )}
      <NotificationContainer />
      <div
        className={`${localStorage.getItem("token") ? "col-lg-10" : "col-lg-12"
          }`}
      >
        <div className="row">
          {/* setting-area */}
          <div className="col-9 p-0 ps-3">
            {/* app color container */}
            <div className="app-color-container mt-1 mb-4">
              <div className="main-title">App Color</div>
              {/* app color-panel */}
              <div className="row justify-content-between pt-3">
                {/* admin app color */}
                <div className="row align-items-center col-6 admin-app-color-section">
                  <div className="col-6 d-flex justify-content-between">
                    <div className="title">App color for admin</div>
                    <div>:</div>
                  </div>
                  <div className="col-6">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      //   id="color-picker"
                      onChange={(e) => setAdminAppColor(e.target.value)}
                      value={adminAppColor}
                    />
                    {/* <span className='ps-2'>{adminAppColor}</span> */}
                    {/* </div> */}
                  </div>
                </div>
                <div className="row align-items-center col-6 admin-app-color-section">
                  <div className="col-6 d-flex justify-content-between">
                    <div className="title">Background color</div>
                    <div>:</div>
                  </div>
                  <div className="col-6">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      // id="color-picker"
                      onChange={(e) => setAppBackgroundColor(e.target.value)}
                      value={appBackgroundColor}
                    />
                    {/* <span className="ps-2">{appBackgroundColor}</span> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="native-container">
              <div className="main-title mb-3">Native</div>
              {/* Inter loading */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Native Loading</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={handleNativeLoading}
                      className={""}
                      name={"native-loading"}
                      id={"native-show"}
                      isSelected={nativeLoading == "true"}
                      label="Preload"
                      value={"true"}
                    />
                    <Redio
                      changed={handleNativeLoading}
                      name={"native-loading"}
                      id={"native-hide"}
                      isSelected={nativeLoading == "false"}
                      label="Onload"
                      value={"false"}
                    />
                  </div>
                </div>
              </div>
              {/* Bottom bannner */}
              <div className="row align-items-center  admin-app-color-section">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Bottom Banner</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={radioBottomBannerChangeHandler}
                      // className={"    "}
                      name="banner"
                      id={"bottom-native"}
                      isSelected={bottombannerRedio == "bottom-native"}
                      label="Native"
                      value={"bottom-native"}
                    />
                    <Redio
                      changed={radioBottomBannerChangeHandler}
                      className={""}
                      name="banner"
                      id={"bottom-banner"}
                      isSelected={bottombannerRedio == "bottom-banner"}
                      label="Banner"
                      value={"bottom-banner"}
                    />
                    <Redio
                      changed={radioBottomBannerChangeHandler}
                      // className={"    "}
                      name="banner"
                      id={"bottom-hide"}
                      isSelected={bottombannerRedio == "bottom-hide"}
                      label="Hide"
                      value={"bottom-hide"}
                    />
                  </div>
                </div>
              </div>
              {/* all screen native */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">All Screen Native</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={radioAllScreenNativeChangeHandler}
                      className={""}
                      name={"screen-show"}
                      id={"screen-show"}
                      isSelected={screenNative == "true"}
                      label="Show"
                      value={"true"}
                    />
                    <Redio
                      changed={radioAllScreenNativeChangeHandler}
                      name={"screen-hide"}
                      id={"screen-hide"}
                      isSelected={screenNative == "false"}
                      label="Hide"
                      value={"false"}
                    />
                  </div>
                </div>
              </div>
              {/* List Native */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">List Native</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex">
                    <Redio
                      changed={handleListNative}
                      className={""}
                      name={"List-Native-show"}
                      id={"List-Native-show"}
                      isSelected={listNative == "true"}
                      label="Show"
                      value={"true"}
                    />
                    <Redio
                      changed={handleListNative}
                      name={"List-Native-hide"}
                      id={"List-Native-hide"}
                      isSelected={listNative == "false"}
                      label="Hide"
                      value={"false"}
                    />
                    <input
                      type="number"
                      value={listNativeInput}
                      onChange={handleListNativeInput}
                      disabled={listNative == "false"}
                    />
                  </div>
                </div>
              </div>
              {/* Static native count */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Static Native Count</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex">
                    <input
                      type="number"
                      value={nativeCount}
                      onChange={handleNativeCount}
                    />
                  </div>
                </div>
              </div>
              {/* exit Dialoge native */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Exit Dialoge Native</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={exitDialogeNative}
                      className={""}
                      name={"exit-Dialoge-show"}
                      id={"exit-Dialoge-show"}
                      isSelected={exitDialoge == "true"}
                      label="Show"
                      value={"true"}
                    />
                    <Redio
                      changed={exitDialogeNative}
                      name={"exit-Dialoge-hide"}
                      id={"exit-Dialoge-hide"}
                      isSelected={exitDialoge == "false"}
                      label="Hide"
                      value={"false"}
                    />
                  </div>
                </div>
              </div>
              {/* native button texct */}
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Native Button Text</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={handleNativebtnText}
                      className={""}
                      name={"Native-Button-Text-Manual"}
                      id={"Native-Button-Text-Manual"}
                      isSelected={nativeBtnText == "Manual"}
                      label="Manual"
                      value={"Manual"}
                    />
                    <Redio
                      changed={handleNativebtnText}
                      name={"Native-Button-Text-default"}
                      id={"Native-Button-Text-default"}
                      isSelected={nativeBtnText == "default"}
                      label="Default"
                      value={"default"}
                    />
                    <input
                      type="text"
                      value={nativeBtnLabel}
                      onChange={(e) => setNativeBtnLabel(e.target.value)}
                      disabled={nativeBtnText == "default"}
                    />
                  </div>
                </div>
              </div>
              {/* native color */}
              <div className="row align-items-center  admin-app-color-section mt-4">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Native Color</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4 row Native-Color-container container-color-box">
                  <div className="col-2">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      // id="color-picker"
                      onChange={(e) => setnativeBackground(e.target.value)}
                      value={nativeBackground}
                    />
                    {/* <span className="ps-2">{nativeBackground}</span> */}
                    {/* </div> */}
                    <div>Background</div>
                  </div>
                  <div className="col-2">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      // id="color-picker"
                      onChange={(e) => setnativeText(e.target.value)}
                      value={nativeText}
                    />
                    {/* <span className="ps-2">{nativeText}</span> */}
                    {/* </div> */}
                    <div>Text</div>
                  </div>
                  <div className="col-2">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      // id="color-picker"
                      onChange={(e) => setnativeBtnBackground(e.target.value)}
                      value={nativeBtnBackground}
                    />
                    {/* <span className="ps-2">{nativeBtnBackground}</span> */}
                    {/* </div> */}
                    <div>Button Background</div>
                  </div>
                  <div className="col-2">
                    {/* <div className="container-color d-flex align-items-center"> */}
                    <input
                      type="text"
                      // id="color-picker"
                      onChange={(e) => setnativeButtonText(e.target.value)}
                      value={nativeButtonText}
                    />
                    {/* <span className="ps-2">{nativeButtonText}</span> */}
                    {/* </div> */}
                    <div>Button Text</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* preview area */}
          <div className="col-3 mt-2">
            <div
              className="mobile-preview-design"
              style={{ backgroundColor: appBackgroundColor }}
            >
              <div>
                {/* admin app name */}
                <div
                  className="preview-app-name-title text-center"
                  style={{ backgroundColor: adminAppColor }}
                >
                  App Name Title
                </div>
                {/* native-part */}
                <div
                  className="px-2 py-2"
                  style={{ backgroundColor: nativeBackground }}
                >
                  <img
                    src="/Images/nativeImage.png"
                    className="nativeImage-div"
                  />
                  <div className="ms-4" style={{ color: nativeText }}>
                    <div className="Test-Ad-title">Test Ad: Goggle Ads</div>
                    <div className="Test-Ad-subtitle">
                      Stay up to date with your Ads Check
                    </div>
                  </div>
                  <button
                    className="w-100 native-btn mt-2"
                    style={{
                      backgroundColor: nativeBtnBackground,
                      color: nativeButtonText,
                    }}
                  >
                    {nativeBtnText == "default" ? "Default" : nativeBtnLabel}
                  </button>
                </div>
              </div>
              {/* start-app-div */}
              <div className="start-app-div">
                <div className="px-2 start-app-btn-div">
                  <button className="w-100 start-app-button">Start App</button>
                </div>
                {/* banner */}
                {bottombannerRedio == "bottom-hide" ? (
                  ""
                ) : (
                  <div className="">
                    <img
                      className="banner-image-section"
                      src={
                        bottombannerRedio == "bottom-native"
                          ? "/Images/nativeBanner.jpg"
                          : "/Images/banner.jpg"
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6">
            <div className="native-container">
              <div className="app-color-container mt-1">
                <div className="main-title">Inter</div>
                <div className="row mt-2 admin-app-open">
                  <div className="col-5 mb-3 d-flex justify-content-between">
                    <div className="title"> Alternate with AppOpen </div>
                    <div>:</div>
                  </div>
                  <div className="col-7 ps-4">
                    <div className="d-flex align-items-center">
                      <Redio
                        changed={(e) => setAlternateShow(e.target.value)}
                        className={""}
                        name={"alternate-appOpen"}
                        id={"alternate-show-app"}
                        isSelected={alternateShow == "true"}
                        label="Show"
                        value={"true"}
                      />
                      <Redio
                        changed={(e) => setAlternateShow(e.target.value)}
                        name={"alternate-appOpen"}
                        id={"alternate-hide-app"}
                        isSelected={alternateShow == "false"}
                        label="Hide"
                        value={"false"}
                      />
                    </div>
                  </div>

                  <div className="col-5 mb-3 d-flex justify-content-between">
                    <div className="title"> Inter Loading </div>
                    <div>:</div>
                  </div>
                  <div className="col-7 ps-4">
                    <div className="d-flex align-items-center">
                      <Redio
                        changed={(e) => setInterData(e.target.value)}
                        className={""}
                        name={"show-loader"}
                        id={"show-loader-inter-show"}
                        isSelected={interData == "true"}
                        label="Preload"
                        value={"true"}
                      />
                      <Redio
                        changed={(e) => setInterData(e.target.value)}
                        name={"show-loader"}
                        id={"show-loader-inter-hide"}
                        isSelected={interData == "false"}
                        label="Onload"
                        value={"false"}
                      />
                    </div>
                  </div>
                  <div className="col-5 mb-3 d-flex justify-content-between">
                    <div className="title">Inter Interval(0==hide)</div>
                    <div>:</div>
                  </div>
                  <div className="col-7 ps-4">
                    <div className="d-flex">
                      <input
                        type="number"
                        value={interInterval}
                        onChange={(e) => setInterInterval(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-5 mb-3 d-flex justify-content-between">
                    <div className="title">Black Click Inter (0==hide) </div>
                    <div>:</div>
                  </div>
                  <div className="col-7 ps-4">
                    <div className="d-flex">
                      <input
                        type="number"
                        value={blackClick}
                        onChange={(e) => setBlackClick(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="native-container">
              <div className="main-title">App Open</div>
              <div className="row align-items-center  admin-app-color-section mt-2">
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">App Loading</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={(e) => setAppOpenLoading(e.target.value)}
                      // className={"    "}
                      name="app-open-loading"
                      id={"app-open-show"}
                      isSelected={appOpenLoading == "true"}
                      label="Preload"
                      value={"true"}
                    />
                    <Redio
                      changed={(e) => setAppOpenLoading(e.target.value)}
                      // className={"    "}
                      name="app-open-loading"
                      id={"app-open-hide"}
                      isSelected={appOpenLoading == "false"}
                      label="Onload"
                      value={"false"}
                    />
                  </div>
                </div>

                <div className="col-3 d-flex justify-content-between">
                  <div className="title">Splash Ads</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={(e) => setSpleshAddData(e.target.value)}
                      // className={"    "}
                      name="splesh"
                      id={"bottom-Inter"}
                      isSelected={spleshAddData == "Inter"}
                      label="Inter"
                      value={"Inter"}
                    />
                    <Redio
                      changed={(e) => setSpleshAddData(e.target.value)}
                      // className={"    "}
                      name="splesh"
                      id={"Open-Adds"}
                      isSelected={spleshAddData == "OpenAds"}
                      label="OpenAds"
                      value={"OpenAds"}
                    />
                    <Redio
                      changed={(e) => setSpleshAddData(e.target.value)}
                      // className={"    "}
                      name="splesh"
                      id={"splesh-hide"}
                      isSelected={spleshAddData == "splesh-hide"}
                      label="Hide"
                      value={"splesh-hide"}
                    />
                  </div>
                </div>
                <div className="col-3 d-flex justify-content-between">
                  <div className="title">App Open</div>
                  <div>:</div>
                </div>
                <div className="col-9 ps-4">
                  <div className="d-flex align-items-center">
                    <Redio
                      changed={(e) => setAppOpenData(e.target.value)}
                      // className={"    "}
                      name="app-open"
                      id={"one-time"}
                      isSelected={appOpenData == "One Time"}
                      label="One Time"
                      value={"One Time"}
                    />
                    <Redio
                      changed={(e) => setAppOpenData(e.target.value)}
                      // className={"    "}
                      name="app-open"
                      id={"every-time"}
                      isSelected={appOpenData == "Every Time"}
                      label="Every Time"
                      value={"Every Time"}
                    />
                    <Redio
                      changed={(e) => setAppOpenData(e.target.value)}
                      // className={"    "}
                      name="app-open"
                      id={"background-hide"}
                      isSelected={appOpenData == "Background Hide"}
                      label="Background Hide"
                      value={"Background Hide"}
                    />
                    <Redio
                      changed={(e) => setAppOpenData(e.target.value)}
                      // className={"    "}
                      name="app-open"
                      id={"app-open-hide"}
                      isSelected={appOpenData == "hide"}
                      label="Hide"
                      value={"hide"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddButton onClick={handleAddSettingOrganic} buttonName={"Update"} />
        </div>
      </div>
    </>
  );
};

export default AddSetting;
